<script setup>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const horizontal = ref(null)
const pinpin = ref(null)
const box = ref(null)
const roadmapSwiper = ref(null)

const carouselProgress = ref(25)

const handleSlideStart = (data) => {
	carouselProgress.value = (data.realIndex + 1) * (100 / 5)
}

const setActiveSlide = (slideIndex) => {
	roadmapSwiper.value.slideTo(slideIndex)
}

const onSwiper = (swiper) => {
	roadmapSwiper.value = swiper
}

onMounted(() => {
	gsap.to(horizontal.value, {
		scrollTrigger: {
			trigger: pinpin.value,
			start: 'center center',
			end: () => "+=" + horizontal.value.offsetWidth,
			pin: true,
			scrub: true,
		},
		x: () => -(horizontal.value.scrollWidth - box.value.offsetWidth) + "px",
		ease: 'none',
	}) 
})

</script>

<template>
	<div>
		<div class="container">
			<div class="pb-3 md:px-s60 border-b md:border-b-0 mb-10 md:mb-0">
				<SplitTitle identifier="roadmap">
					<h2 class="h1 max-w-[996px] mb-10 xl:mb-s90">{{ $t('roadmap.title') }}</h2>
				</SplitTitle>
				<div class="grid grid-cols-12 xl:gap-8">
					<div class="col-span-12 xl:col-span-5">
						<p class="h2 mb-s30 xl:mb-s60" v-html="$t('roadmap.text')"></p>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<div class="pb-5 md:px-s60">

				<div class="xl:hidden">
					<Swiper
						:space-between="30"
						@swiper="onSwiper"
						:slides-per-view="1"
						@slide-change="handleSlideStart"
					>
						<SwiperSlide>
							<RoadSlide
								year="2024"
								:title="$t('roadmap.slide1.title')"
								image="/img/slide1.png"
								:text="$t('roadmap.slide1.text')"
								:list="[
									$t('roadmap.slide1.list1'),
									$t('roadmap.slide1.list2'),
									$t('roadmap.slide1.list3')
								]"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<RoadSlide
								year="2025"
								:title="$t('roadmap.slide2.title')"
								image="/img/slide2.png"
								:text="$t('roadmap.slide2.text')"
								:list="[
									$t('roadmap.slide2.list1'),
									$t('roadmap.slide2.list2'),
									$t('roadmap.slide2.list3')
								]"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<RoadSlide
								year="2025"
								:title="$t('roadmap.slide3.title')"
								image="/img/slide3.png"
								:text="$t('roadmap.slide3.text')"
								:list="[
									$t('roadmap.slide3.list1'),
									$t('roadmap.slide3.list2'),
									$t('roadmap.slide3.list3')
								]"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<RoadSlide
								year="2026"
								:title="$t('roadmap.slide4.title')"
								image="/img/slide4.png"
								:text="$t('roadmap.slide4.text')"
								:list="[
									$t('roadmap.slide4.list1'),
									$t('roadmap.slide4.list2'),
									$t('roadmap.slide4.list3')
								]"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<RoadSlide
								year="2026"
								:title="$t('roadmap.slide5.title')"
								image="/img/slide5.png"
								:text="$t('roadmap.slide5.text')"
								:list="[
									$t('roadmap.slide5.list1'),
									$t('roadmap.slide5.list2'),
									$t('roadmap.slide5.list3')
								]"
							/>
						</SwiperSlide>
					</Swiper>
					<div class="flex justify-around">
						<div class="py-3 cursor-pointer" @click="setActiveSlide(0)">2024</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(1)">2025</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(2)">2025</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(3)">2026</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(4)">2026</div>
					</div>
					<div class="progress-bar h-[3px]">
						<div class="progress-slide h-[3px] bg-dark transition-all duration-300" :style="{ width: `${carouselProgress}%` }"></div>
					</div>
					<div class="flex justify-between mt-6">
						<svg @click="roadmapSwiper.slidePrev()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
							<path d="M9.45703 17.2373L1.00243 8.78234L9.45703 0.328125" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M1.00058 8.78125L24.0586 8.78125" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
						<svg @click="roadmapSwiper.slideNext()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
							<path d="M14.6016 0.32907L23.0562 8.78407L14.6016 17.2383" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M23.058 8.78515L0 8.78516" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
					</div>
				</div>

				<div class="overflow-hidden hidden xl:block" id="pinpin" ref="pinpin">
					<div class="horizontal" ref="horizontal">
						<div class="box">
							<RoadSlide
								year="2024"
								:title="$t('roadmap.slide1.title')"
								image="/img/slide1.png"
								:text="$t('roadmap.slide1.text')"
								:list="[
									$t('roadmap.slide1.list1'),
									$t('roadmap.slide1.list2'),
									$t('roadmap.slide1.list3')
								]"
							/>
						</div>
						<div class="box">
							<RoadSlide
								year="2025"
								:title="$t('roadmap.slide2.title')"
								image="/img/slide2.png"
								:text="$t('roadmap.slide2.text')"
								:list="[
									$t('roadmap.slide2.list1'),
									$t('roadmap.slide2.list2'),
									$t('roadmap.slide2.list3')
								]"
							/>
						</div>
						<div class="box">
							<RoadSlide
								year="2025"
								:title="$t('roadmap.slide3.title')"
								image="/img/slide3.png"
								:text="$t('roadmap.slide3.text')"
								:list="[
									$t('roadmap.slide3.list1'),
									$t('roadmap.slide3.list2'),
									$t('roadmap.slide3.list3')
								]"
							/>
						</div>
						<div class="box">
							<RoadSlide
								year="2026"
								:title="$t('roadmap.slide4.title')"
								image="/img/slide4.png"
								:text="$t('roadmap.slide4.text')"
								:list="[
									$t('roadmap.slide4.list1'),
									$t('roadmap.slide4.list2'),
									$t('roadmap.slide4.list3')
								]"
							/>
						</div>
						<div class="box" ref="box">
							<RoadSlide
								year="2026"
								:title="$t('roadmap.slide5.title')"
								image="/img/slide5.png"
								:text="$t('roadmap.slide5.text')"
								:list="[
									$t('roadmap.slide5.list1'),
									$t('roadmap.slide5.list2'),
									$t('roadmap.slide5.list3')
								]"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.horizontal {
	display: flex;
	gap: 40px;
	border-top: 1px solid theme('colors.dark');
}

.box {
	min-width: 100%;
	width: 100%;
	height: 100vh;
	
}

.progress-bar {
	position: relative;

	&::before {
		position: absolute;
		top: 50%;
		transform: translateY(-100%);
		content: '';
		width: 100%;
		height: 1px;
		background-color: theme('colors.dark');
	}
}

:deep(.swiper-wrapper) {
	align-items: flex-end;
}
</style>