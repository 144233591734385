<script setup>

const props = defineProps({
	title: {
		type: String
	},
	position: {
		type: String
	},
	image: {
		type: String
	},
	text: {
		type: String
	}
})

const isFlipped = ref(false)

</script>

<template>
	<div @click="isFlipped = !isFlipped" class="cursor-pointer w-full">
		<div class="flipcard mb-6 aspect-[291/388]">
			<div class="content" :class="{'flipped' : isFlipped}">
				<div class="front bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: `url(${image})` }">
					<svg class="absolute top-3 right-3" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0295 30.4409C23.3301 30.4409 30.0591 23.6265 30.0591 15.2205C30.0591 6.81443 23.3301 0 15.0295 0C6.72896 0 0 6.81443 0 15.2205C0 23.6265 6.72896 30.4409 15.0295 30.4409ZM13.7019 16.7865V20.3745H16.6008V16.7865H20.1208V13.8974H16.6008V10.3094H13.7019V13.8974H10.1819V16.7865H13.7019Z" fill="#1D1D1D"/>
					</svg>
				</div>

				<div class="back bg-melrose overflow-hidden">
					<svg class="absolute top-3 right-3" xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M15.0874 30.4815C23.4199 30.4815 30.1748 23.658 30.1748 15.2408C30.1748 6.82352 23.4199 0 15.0874 0C6.75486 0 0 6.82352 0 15.2408C0 23.658 6.75486 30.4815 15.0874 30.4815ZM10.221 16.8106H13.7546H16.6646H20.1982V13.9177H16.6646L13.7546 13.9173V13.9177H10.221V16.8106Z" fill="#1D1D1D"/>
					</svg>
					<div class="p-7 text relative h-full">
						<p v-html="text" class="text-[6.5cqw] leading-[115%] absolute bottom-7 left-7 right-7"></p>
					</div>
				</div>
			</div>
		</div>
		<div class="text-white">
			<p class="text-xl font-bold mb-3 leading-6">{{ title }}</p>
			<p class="leading-5">{{ position }}</p>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.text {
		container-type: size;
	}
	.flipcard {
		perspective: 2000px;
	}
	.content {
		position: absolute;
		width: 100%;
		height: 100%;
		transition: transform 1s;
		transform-style: preserve-3d;
		border-radius: 22px;

		&.flipped {
			transform: rotateY( 180deg ) ;
			transition: transform 0.5s;
		}
	}
	.front,
	.back {
		position: absolute;
		height: 100%;
		width: 100%;
		border-radius: 22px;
		backface-visibility: hidden;
	}
	.back {
		transform: rotateY( 180deg );
	}
</style>