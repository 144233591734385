export default function useHeadData(metadata) {
  useHead({
    title: metadata.title
      ? `${metadata.title}`
      : 'BE-A',
    meta: [
      {
        name: 'description',
        content: metadata.description,
      },
			{
        name: 'author',
        content:
          metadata.author ||
          'Brainz Disruptive',
      },
			{
        name: 'keywords',
        content:
          metadata.keywords ||
          '',
      },
			{
        name: 'copyright',
        content:
          metadata.copyright ||
          '',
      },
      // facebook open graph
      {
        hid: 'og:title',
        property: 'og:title',
        content: metadata.og.title
          ? `${metadata.og.title}`
          : 'BE-A',
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content:
          metadata.og.description ||
          '',
      },
			{
        hid: 'og:type',
        property: 'og:type',
        content:
          metadata.og.type ||
          'website',
      },
			{
        hid: 'og:site_name',
        property: 'og:site_name',
        content:
          metadata.og.site_name ||
          'be-a.cz',
      },
			{
        hid: 'og:image',
        property: 'og:image',
        content: metadata.og.image
      },
			{
        hid: 'og:url',
        property: 'og:url',
        content: metadata.url
      },
			// twitter
			{
        hid: 'twitter:title',
        property: 'twitter:title',
        content: metadata.twitter.title
          ? `${metadata.twitter.title}`
          : 'BE-A',
      },
			{
        hid: 'twitter:url',
        property: 'twitter:url',
        content: metadata.url
      },
			{
        hid: 'twitter:image',
        property: 'twitter:image',
        content: metadata.twitter.image
      },
			{
        hid: 'twitter:description',
        property: 'twitter:description',
        content:
          metadata.twitter.description ||
          '',
      },
    ],
  })
}
