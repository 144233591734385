<template>
	<div class="bg-dark">
		<div class="container">
			<div class="py-16 md:py-24 md:px-s60">
				<div class="grid grid-cols-12 sm:gap-8 items-center">
					<div class="col-span-12 xl:col-span-6 order-2 xl:order-1">
						<SplitTitle identifier="newsletter">
							<p class="text-[34px] xl:text-[70px] leading-[36px] xl:leading-[68px] text-melrose font-bold mb-7 xl:mb-14" v-html="$t('newsletter.title')"></p>
						</SplitTitle>
						<p class="text-white mb-10 xl:mb-14" v-html="$t('newsletter.text')"></p>
						<FormNewsletter />
					</div>
					<div class="col-span-12 xl:col-span-6 order-1 xl:order-2">
						<NuxtImg class="mx-auto w-full max-w-full xl:w-full mb-8 sm:mb-0" src="/img/newsletter.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>