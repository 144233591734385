<script setup>

const isLoading = ref(false);

const email = ref(null)
const success = ref(false)
const emailError = ref(false)

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const handleSubmit = async () => {
	isLoading.value = true

	if(validateEmail(email.value)) {
		emailError.value = false

		const responseData = await $fetch('https://api2.ecomailapp.cz/lists/2/subscribe', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
    		'key': '8b78482f43f2d409a424a82f705b5b0401649e73e2f531c5a1d97ebd92c73d08',
  		},
			body: {
				subscriber_data: {
        	email: email.value
				}
			}
    })

		success.value = true
	} else {
		emailError.value = true
	}

	isLoading.value = false
}

</script>

<template>
	<div>
		<form v-if="!success" @submit.prevent="handleSubmit">
			<div class="sm:flex">
				<input @input="emailError = false" class="sm:mr-5 mb-4 sm:mb-0 w-full" type="email" name="email" v-model="email" :placeholder="$t('newsletter.email')" />
				<button class="button uppercase w-full sm:w-auto basis-[167px]" type="submit" :disabled="isLoading">
					<span class="text">{{ $t('general.submit') }}</span>
				</Button>
			</div>
			<div v-if="emailError" class="mt-4">
				<p class="pl-6 text-[#FF5F56]">{{ $t('newsletter.email.error') }}</p>
			</div>
		</form>
		<div v-else>
			<div class="text-2xl text-melrose">{{ $t('newsletter.success') }}</div>
		</div>
	</div>
</template>