<script setup>

const props = defineProps({
	title: {
		type: String
	},
	text: {
		type: String
	}
})

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const noodle = ref(null)

onMounted(() => {

	gsap.to(noodle.value, {
		scrollTrigger: {
			trigger: noodle.value,
			start: 'top bottom',
		},
		x: 0,
		duration: 0.5,
		stagger: 0.25,
	});
})

</script>

<template>
	<div class="overflow-hidden mb-8 lg:mb-0">
		<svg class="mb-1 lg:mb-6 noodle -translate-x-full" ref="noodle" xmlns="http://www.w3.org/2000/svg" width="128" height="96" viewBox="0 0 128 96" fill="none">
			<path d="M0.999996 6.38657L1 94.4883C0.999999 77.2502 15.069 63.2756 32.4236 63.2756L95.2707 63.2756C112.625 63.2756 126.694 49.301 126.694 32.0629C126.694 14.8248 112.625 0.850238 95.2707 0.850238L6.57188 0.850242C3.50734 0.850243 0.999996 3.34076 0.999996 6.38473L0.999996 6.38657Z" fill="#9FB4FF" stroke="#9FB4FF" stroke-width="1.12478" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
		<h2 class="mb-1 lg:mb-6">{{ title }}</h2>
		<p>{{ text}}</p>
	</div>
</template>