<script setup>

const carouselProgress = ref(33.333333)

const handleSlideStart = (data) => {
	console.log(data)
	carouselProgress.value = (data.realIndex + 1) * (100 / 3)
}

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const pecr = ref(null)

onMounted(() => {

	gsap.to('.pecr > a', {
		scrollTrigger: {
			trigger: pecr.value,
			start: 'top 95%',
		},
		y: 0,
		stagger: 0.25,
		duration: 0.5,
		opacity: 1,
	})

	// gsap.to('.cite', {
	// 	scrollTrigger: {
	// 		trigger: '.cite',
	// 		start: 'top 75%',
	// 	},
	// 	x: 0,
	// 	duration: 1,
	// 	opacity: 1,
	// })
})

</script>

<template>
	<div class="container">
		<div class="pb-5 md:px-s60">
			<SplitTitle identifier="hoteliers">
				<h2 class="h1 max-w-[1260px] mb-10 lg:mb-s90" v-html="$t('references.title')"/>
			</SplitTitle>
			<a href="https://www.pecr.cz/" target="_blank" class="inline-block link text-xl md:text-[26px] mb-10 lg:mb-s90">www.pecr.cz</a>
			<div class="grid grid-cols-1 md:grid-cols-2 md:gap-8 mb-7 pecr" ref="pecr">
				<a href="https://www.pecr.cz/" target="_blank" class="mb-6 md:mb-0 opacity-0 translate-y-10">
					<NuxtImg class="mb-5 md:mb-7" src="/img/pecr1.png" />
					<p class="text-lg"><strong>Hotel Pecr Well |</strong> Hotel</p>
				</a>
				<a href="https://www.pecr.cz/" target="_blank" class="opacity-0 translate-y-10">
					<NuxtImg class="mb-5 md:mb-7" src="/img/pecr2.png" />
					<p class="text-lg"><strong>Hotel Pecr Deep |</strong> Hotel</p>
				</a>
			</div>
			<!-- <SplitTitle identifier="hoteliers">
				<h2 class="h1 max-w-[996px] mb-10 lg:mb-s90">Tito hoteliéři už BE-U vyzkoušeli</h2>
			</SplitTitle>
			<div class="grid grid-cols-12 lg:gap-8 mb-7">
				<div class="col-span-12 lg:col-span-5">
					<p class="h2 mb-s60">
						Přesuňte se také do budoucnosti hotelové recepce. Takhle to díky BE-A může vypadat i&nbsp;u&nbsp;vás.
					</p>
				</div>
				<div class="hidden lg:block col-span-7 -mt-24">
					<div class="grid grid-cols-2 gap-7 items-center">
						<div class="col-span-1 flex justify-end overflow-hidden">
							<div class="shrink-0 max-w-full relative cite opacity-0 translate-x-full">
								<div class="absolute w-[75%] left-10 xl:left-14 top-9 xl:top-6">
									<p class="mb-5 text-sm xl:text-base">Naši recepční bývali v noci nerudní. Tohle už je díky BE-E naštěstí minulost.</p>
									<p class="text-xs font-bold">Hotel Ještěd</p>
								</div>
								<svg class="shrink-0 max-w-full" xmlns="http://www.w3.org/2000/svg" width="303" height="227" viewBox="0 0 303 227" fill="none">
									<path d="M302.078 13.3946L302.078 226.484C302.078 184.791 268.266 150.991 226.559 150.991L75.5192 150.991C33.8114 150.991 -0.000361434 117.191 -0.000359611 75.4974C-0.000357789 33.804 33.8114 0.0039403 75.5193 0.00394212L288.687 0.00395144C296.052 0.00395176 302.078 6.02773 302.078 13.3901L302.078 13.3946Z" fill="#B6E8E5"/>
								</svg>
							</div>
						</div>
						<div class="col-span-1 overflow-hidden">
							<div class="shrink-0 inline-block relative cite opacity-0 -translate-x-full">
								<div class="absolute w-[75%] left-6 xl:left-10 top-8 xl:top-6">
									<p class="mb-5 text-sm xl:text-base">Díky BE-E jsme ušetřili XY % nákladů na personál a to už je nějaké číslo.</p>
									<p class="text-xs font-bold">Hotel Ještěd</p>
								</div>
								<svg class="2xl:mb-4 max-w-full" xmlns="http://www.w3.org/2000/svg" width="303" height="228" viewBox="0 0 303 228" fill="none">
									<path d="M0.0781157 14.0821L0.078125 227.172C0.0781232 185.478 33.8899 151.678 75.5978 151.678L226.637 151.678C268.345 151.678 302.157 117.878 302.157 76.1849C302.157 34.4915 268.345 0.69144 226.637 0.691442L13.4689 0.691451C6.10397 0.691452 0.0781154 6.71523 0.0781157 14.0776L0.0781157 14.0821Z" fill="#9FB4FF"/>
								</svg>
							</div>
							<div class="shrink-0 inline-block  relative cite opacity-0 -translate-x-full">
								<div class="absolute w-[85%] left-6 xl:left-8 top-8 xl:top-8">
									<p class="mb-2 xl:mb-5 text-white text-sm xl:text-base">Napojení na naše PMS proběhlo hladce. Konečně jsme plně digitální moderní hotel.</p>
									<p class="text-xs font-bold text-white">Hotel Ještěd</p>
								</div>
								<svg class="max-w-full" xmlns="http://www.w3.org/2000/svg" width="303" height="228" viewBox="0 0 303 228" fill="none">
									<path d="M0.738272 14.2344L0.738281 227.324C0.738279 185.631 34.55 151.831 76.2579 151.831L227.297 151.831C269.005 151.831 302.817 118.031 302.817 76.3373C302.817 34.6438 269.005 0.843784 227.297 0.843786L14.1291 0.843795C6.76413 0.843796 0.738272 6.86757 0.738272 14.2299L0.738272 14.2344Z" fill="#1D1D1D"/>
								</svg>
							</div>
						</div>
					</div>
				</div>
				<div class="col-span-12 lg:hidden mb-s60">
					<div class="bg-melrose rounded-lg p-4 mb-4">
						<p class="mb-5">Díky BE-E jsme ušetřili XY % nákladů na personál a to už je nějaké číslo.</p>
						<p class="font-bold">Hotel Ještěd</p>
					</div>
					<div class="bg-turquoise rounded-lg p-4 mb-4">
						<p class="mb-5">Díky BE-E jsme ušetřili XY % nákladů na personál a to už je nějaké číslo.</p>
						<p class="font-bold">Hotel Ještěd</p>
					</div>
					<div class="bg-dark rounded-lg p-4 text-white">
						<p class="mb-5">Díky BE-E jsme ušetřili XY % nákladů na personál a to už je nějaké číslo.</p>
						<p class="font-bold">Hotel Ještěd</p>
					</div>
				</div>
			</div>

			<div class="hidden xl:grid grid-cols-3 gap-8">
				<FlipCard
					title="<strong>U Smrků</strong> | Motel"
					image="/img/flip2.png"
				/>
				<FlipCard
					title="<strong>U Smrků</strong> | Hotel"
					image="/img/flip1.png"
				/>
				<FlipCard
					title="<strong>U Smrků</strong> | Hotel"
					image="/img/flip2.png"
				/>
			</div>
			<div class="xl:hidden">
				<Swiper
					:slides-per-view="1"
					:loop="true"
					@slide-change="handleSlideStart"
				>
					<SwiperSlide>
						<FlipCard
						class="w-full"
							title="<strong>U Smrků</strong> | Hotel"
							image="/img/flip2.png"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<FlipCard
						class="w-full"
							title="<strong>U Smrků</strong> | Motel"
							image="/img/flip1.png"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<FlipCard
						class="w-full"
							title="<strong>U Smrků</strong> | Motel"
							image="/img/flip2.png"
						/>
					</SwiperSlide>
				</Swiper>
				<div class="progress-bar h-[3px] mt-6">
					<div class="progress-slide h-[3px] bg-dark transition-all duration-300" :style="{ width: `${carouselProgress}%` }"></div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.progress-bar {
		position: relative;

		&::before {
			position: absolute;
			top: 50%;
			transform: translateY(-100%);
			content: '';
			width: 100%;
			height: 1px;
			background-color: theme('colors.dark');
		}
	}
</style>