<script setup>

const props = defineProps({
	identifier: {
		type: String
	},
})

import SplitType from 'split-type'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const splitTitle = ref(null)

onMounted(() => {
	const text = new SplitType(splitTitle.value)

	gsap.to(`.${props.identifier} .char`, {
		scrollTrigger: {
			trigger: splitTitle.value,
			start: 'top 90%',
		},
		y: 0,
		stagger: 0.02,
		duration: 0.1,
		opacity: 1,
	})
	gsap.to(splitTitle.value, {
		opacity: 1
	})
})

</script>

<template>
	<div :class="identifier">
		<div ref="splitTitle" class="opacity-0">
			<slot />
		</div>
	</div>
</template>