<script setup>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const carouselProgress = ref(12.5)

const peopleSwiper = ref(null)

const onSwiper = (swiper) => {
	peopleSwiper.value = swiper
}

const handleSlideStart = (data) => {
	carouselProgress.value = (data.realIndex + 1) * (100 / 8)
}

const people = ref(null)

onMounted(() => {

	gsap.to('.people > div', {
		scrollTrigger: {
			trigger: people.value,
			start: 'top 95%',
		},
		y: 0,
		stagger: 0.25,
		duration: 0.5,
		opacity: 1,
	})
})

</script>

<template>
	<div class="container">
		<div class="bg-dark rounded-[30px] pt-16 md:pt-28">
			<div class="px-s30 md:px-s60">
				<div class="flex justify-between mb-10 md:mb-s90">
					<SplitTitle identifier="team">
						<h2 class="h1 max-w-[700px] text-melrose" v-html="$t('team.title')" />
					</SplitTitle>
					<a class="hidden lg:block" href="https://flaerobotics.ai/" target="_blank">
						<svg class="mt-4" xmlns="http://www.w3.org/2000/svg" width="254" height="24" viewBox="0 0 254 24" fill="none">
							<path d="M0 0.558594H15.0259V3.12902H2.84735V10.5436H13.8692V13.114H2.84735V23.3252H0V0.558594Z" fill="white"/>
							<path d="M18.9296 0.558594H21.777V20.7511H33.6461V23.3215H18.9258V0.558594H18.9296Z" fill="white"/>
							<path d="M83.8086 0.558594H93.8826C98.7339 0.558594 101.105 3.77811 101.105 6.93087C101.105 9.79431 99.3413 12.5576 95.9176 13.433L102.363 23.3178H99.0048L92.9 13.726H86.6598V23.3178H83.8125V0.558594H83.8086ZM93.2366 11.2891C96.3238 11.2891 98.1227 9.6645 98.1227 7.16084C98.1227 4.85376 96.5288 3.12902 93.3758 3.12902H86.6598V11.2928H93.2404L93.2366 11.2891Z" fill="white"/>
							<path d="M105.074 11.9323C105.074 5.6898 109.009 0 116.642 0C124.274 0 128.209 5.6898 128.209 11.9323C128.209 18.1747 124.274 23.8979 116.642 23.8979C109.009 23.8979 105.074 18.1747 105.074 11.9323ZM116.642 21.3312C122.441 21.3312 125.222 16.973 125.222 11.9323C125.222 6.89155 122.441 2.56671 116.642 2.56671C110.842 2.56671 108.061 6.89155 108.061 11.9323C108.061 16.973 110.842 21.3312 116.642 21.3312Z" fill="white"/>
							<path d="M133.469 0.558594H143.914C148.085 0.558594 150.054 3.25884 150.054 5.95537C150.054 8.13263 148.901 10.3136 146.46 11.1889C149.582 12.0346 151.04 14.4715 151.04 16.8454C151.04 20.1279 148.394 23.3178 143.442 23.3178H133.469V0.558594ZM142.626 10.1838C145.613 10.1838 147.067 8.36259 147.067 6.47836C147.067 4.69056 145.744 3.12902 143.098 3.12902H136.316V10.1838H142.626ZM143.268 20.7511C146.526 20.7511 148.05 18.6702 148.05 16.556C148.05 14.4418 146.421 12.5242 143.404 12.5242H136.316V20.7511H143.268Z" fill="white"/>
							<path d="M154.602 11.9323C154.602 5.6898 158.536 0 166.169 0C173.802 0 177.736 5.6898 177.736 11.9323C177.736 18.1747 173.802 23.8979 166.169 23.8979C158.536 23.8979 154.602 18.1747 154.602 11.9323ZM166.165 21.3312C171.964 21.3312 174.746 16.973 174.746 11.9323C174.746 6.89155 171.964 2.56671 166.165 2.56671C160.366 2.56671 157.584 6.89155 157.584 11.9323C157.584 16.973 160.366 21.3312 166.165 21.3312Z" fill="white"/>
							<path d="M187.465 3.12902H179.426V0.558594H198.351V3.12902H190.312V23.3215H187.465V3.12902Z" fill="white"/>
							<path d="M202.426 0.558594H205.273V23.3215H202.426V0.558594Z" fill="white"/>
							<path d="M210.703 11.9656C210.703 4.42128 215.721 0 222.236 0C227.493 0 231.733 2.85974 232.074 7.83738H229.087C228.716 4.35822 225.83 2.57042 222.065 2.57042C217.284 2.57042 213.655 5.78994 213.655 11.8395C213.655 16.9767 216.336 21.3349 221.965 21.3349C225.799 21.3349 228.886 19.3171 229.188 15.5449H232.17C231.764 21.0085 227.288 23.9016 221.86 23.9016C214.297 23.9016 210.703 18.2749 210.703 11.9693V11.9656Z" fill="white"/>
							<path d="M235.898 16.0345H238.885C239.225 19.8067 242.038 21.4017 245.431 21.4017C248.824 21.4017 250.619 20.0033 250.619 17.5627C250.619 15.1221 248.82 14.2467 246.514 13.6273L242.645 12.5851C238.506 11.4797 236.541 9.72533 236.541 6.43904C236.541 2.1142 239.964 0 244.68 0C249.396 0 253.091 1.98438 253.296 6.56886H250.309C250.073 3.51254 247.462 2.50366 244.576 2.50366C241.454 2.50366 239.523 3.70542 239.523 6.30922C239.523 8.74983 241.253 9.56213 243.965 10.278L247.493 11.1867C251.191 12.1288 253.632 13.5272 253.632 17.1695C253.632 21.6576 249.903 23.9016 245.322 23.9016C239.999 23.9016 236.065 20.8453 235.895 16.0308L235.898 16.0345Z" fill="white"/>
							<path d="M61.1359 2.11719H54.2265V2.26555H49.4525L48.9883 2.9777H54.2265V3.12607H61.1359V2.9777H73.891V2.26555H61.1359V2.11719Z" fill="white"/>
							<path d="M61.136 5.84766H54.2266V6.85654H61.136V5.84766Z" fill="white"/>
							<path d="M61.136 9.57031H54.2266V10.5829H61.136V10.4345H73.891V9.71868H61.136V9.57031Z" fill="white"/>
							<path d="M54.2257 16.1651H61.1352V15.1562H54.2257V15.3009H40.933L40.4648 16.0168H54.2257V16.1651Z" fill="white"/>
							<path d="M61.1311 18.8828H54.2216V19.0312H38.4916L38.0273 19.7433H54.2216V19.8917H61.1311V19.7433H73.8861V19.0312H61.1311V18.8828Z" fill="white"/>
							<path d="M61.1358 3.98047H54.2264V4.12883H48.2376L47.7695 4.84099H54.2264V4.98935H61.1358V4.84099H73.8908V4.12883H61.1358V3.98047Z" fill="white"/>
							<path d="M61.136 7.70312H54.2266V8.71201H61.136V7.70312Z" fill="white"/>
							<path d="M61.136 11.4336H54.2266V12.4425H61.136V12.2941H73.891V11.5782H61.136V11.4336Z" fill="white"/>
							<path d="M54.2217 18.0245H61.1312V17.0156H54.2217V17.1603H39.7103L39.2422 17.8761H54.2217V18.0245Z" fill="white"/>
							<path d="M61.136 13.2969H54.2266V14.3058H61.136V14.1574H73.891V13.4415H61.136V13.2969Z" fill="white"/>
							<path d="M61.136 20.7461H54.2266V21.755H61.136V21.6066H73.891V20.8945H61.136V20.7461Z" fill="white"/>
							<path d="M61.136 22.6055H54.2266V23.6144H61.136V23.466H73.891V22.7538H61.136V22.6055Z" fill="white"/>
							<path d="M61.1321 0.406177V0.257812H54.2227V0.406177H50.6674L50.2031 1.11833H54.2227V1.26669H61.1321V1.11833H73.8872V0.406177H61.1321Z" fill="white"/>
							<path d="M52.5781 7.85156H45.7963L45.332 8.56742H52.11L52.5781 7.85156Z" fill="white"/>
							<path d="M53.8007 5.99219H47.0189L46.5547 6.70434H53.3326L53.8007 5.99219Z" fill="white"/>
							<path d="M48.9296 13.4414H42.1478L41.6836 14.1573H48.4615L48.9296 13.4414Z" fill="white"/>
							<path d="M35.5938 23.47H42.3755L42.8398 22.7578H36.0619L35.5938 23.47Z" fill="white"/>
							<path d="M36.8125 21.6067H43.5943L44.0585 20.8945H37.2806L36.8125 21.6067Z" fill="white"/>
							<path d="M50.1484 11.5781H43.3666L42.9023 12.294H49.6803L50.1484 11.5781Z" fill="white"/>
							<path d="M51.3593 9.71875H44.5814L44.1133 10.4346H50.8951L51.3593 9.71875Z" fill="white"/>
						</svg>
					</a>
				</div>
				<div class="lg:grid grid-cols-12 gap-8">
					<div class="col-span-8">
						<p class="h2 mb-s60 text-white" v-html="$t('team.text')" />
					</div>
				</div>

				<div class="hidden md:grid grid-cols-12 gap-8 mb-20 people" ref="people">
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Filip Linek"
							position="CEO and chairman of the Board of Directors"
							image="/img/team/linek.jpg"
							:text="$t('team.linek')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Vít Kluganost"
							position="Delivery director, Profinit"
							image="/img/team/kluganost.jpg"
							:text="$t('team.kluganost')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Patricie Linek Honslová"
							position="Marketing Director"
							image="/img/team/honslova.jpg"
							:text="$t('team.honslova')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Michal Podzimek"
							position="Delivery Manager, Profinit"
							image="/img/team/podzimek.jpg"
							:text="$t('team.podzimek')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Petr Paščenko"
							position="Head of Data Science, Profinit"
							image="/img/team/pascenko.jpg"
							:text="$t('team.pascenko')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Adam Szabó"
							position="Senior data scientist"
							image="/img/team/szabo.jpg"
							:text="$t('team.szabo')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Jan Palášek"
							position="Senior ai developer"
							image="/img/team/palasek.jpg"
							:text="$t('team.palasek')"
						/>
					</div>
					<div class="col-span-6 2xl:col-span-3 opacity-0 translate-y-10">
						<Person
							title="Dominik Matula"
							position="Senior data scientist"
							image="/img/team/matula.jpg"
							:text="$t('team.matula')"
						/>
					</div>
				</div>

				<div class="md:hidden mb-20">
					<Swiper
						:slides-per-view="1"
						:space-between="30"
						:loop="true"
						@slide-change="handleSlideStart"
						@swiper="onSwiper"
					>
						<SwiperSlide>
							<Person
								title="Filip Linek"
								position="CEO and chairman of the Board of Directors"
								image="/img/team/linek.png"
								:text="$t('team.linek')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Vít Kluganost"
								position="Delivery director, Profinit"
								image="/img/team/kluganost.png"
								:text="$t('team.kluganost')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Patricie Linek Honslová"
								position="Marketing Director"
								image="/img/team/honslova.png"
								:text="$t('team.honslova')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Michal Podzimek"
								position="Delivery Manager, Profinit "
								image="/img/team/podzimek.png"
								:text="$t('team.podzimek')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Petr Paščenko"
								position="Head of Data Science, Profinit"
								image="/img/team/pascenko.jpg"
								:text="$t('team.pascenko')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Adam Szabó"
								position="Senior data scientist"
								image="/img/team/szabo.jpg"
								:text="$t('team.szabo')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Jan Palášek"
								position="Senior ai developer"
								image="/img/team/palasek.jpg"
								:text="$t('team.palasek')"
							/>
						</SwiperSlide>
						<SwiperSlide>
							<Person
								title="Dominik Matula"
								position="Senior data scientist"
								image="/img/team/matula.jpg"
								:text="$t('team.matula')"
							/>
						</SwiperSlide>
					</Swiper>
					<div class="progress-bar h-[3px] mt-6 mb-8">
						<div class="progress-slide h-[3px] bg-white transition-all duration-300" :style="{ width: `${carouselProgress}%` }"></div>
					</div>
					<div class="flex justify-between">
						<svg @click="peopleSwiper.slidePrev()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
							<path d="M9.45703 17.2373L1.00243 8.78234L9.45703 0.328125" stroke="white" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M1.00058 8.78125L24.0586 8.78125" stroke="white" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
						<svg @click="peopleSwiper.slideNext()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
							<path d="M14.6016 0.32907L23.0562 8.78407L14.6016 17.2383" stroke="white" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M23.058 8.78515L0 8.78516" stroke="white" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
					</div>
				</div>

				<blockquote class="pb-20 md:pb-32">
					<div class="inline" v-html="$t('team.cite')" />
					<span>&mdash; Filip Linek, CEO</span>
				</blockquote>

			</div>

			
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.logos {
		-ms-overflow-style: none;
		scrollbar-width: none;
		overflow-x: scroll;

		&::-webkit-scrollbar {
			display: none;
		}
	}
	.progress-bar {
		position: relative;

		&::before {
			position: absolute;
			top: 50%;
			transform: translateY(-100%);
			content: '';
			width: 100%;
			height: 1px;
			background-color: theme('colors.white');
		}
	}
</style>