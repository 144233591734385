<template>
	<div class="container ">
		<div class="pb-5 md:px-s60">
			<SplitTitle identifier="ai">
				<h2 class="h1 max-w-[996px] mb-10 lg:mb-s90">{{ $t('ai.title') }}</h2>
			</SplitTitle>
			<div class="xl:grid grid-cols-12 xl:gap-8">
				<div class="col-span-5">
					<p class="h2 mb-s60" v-html="$t('ai.text')"></p>
					<Button class="hidden xl:inline-block">{{ $t('general.arrange-demo') }}</Button>
				</div>
				<div class="col-span-6 col-start-7">
					<div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-20">
						<NoodlePoint
							:title="$t('ai.noodle1.title')"
							:text="$t('ai.noodle1.text')"
						/>
						<NoodlePoint
							:title="$t('ai.noodle2.title')"
							:text="$t('ai.noodle2.text')"
						/>
						<NoodlePoint
							:title="$t('ai.noodle3.title')"
							:text="$t('ai.noodle3.text')"
						/>
						<NoodlePoint
							:title="$t('ai.noodle4.title')"
							:text="$t('ai.noodle4.text')"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>