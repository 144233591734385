<script setup>

const { locale } = useI18n()

const date = ref(new Date())

const dayName = (locale) => {
  return date.value.toLocaleDateString(locale, { weekday: 'long' });
}

const getDate = () => {
  return `${date.value.getDate()}.${date.value.getMonth() + 1}.${date.value.getFullYear()}`
}

</script>

<template>
	<div class="text-xs flex items-center">
		<div class="mr-5">
			<p class="mb-2">
				<span class="capitalize">{{ dayName(locale === 'cs' ? 'cs-CZ' : 'en-US') }}</span>
				<br>
				{{ getDate() }}
			</p>
			<!-- <p>Liberec</p> -->
		</div>
		<!-- <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
			<path d="M18.4486 25.5223C22.8536 25.5223 26.4246 21.9513 26.4246 17.5463C26.4246 13.1413 22.8536 9.57031 18.4486 9.57031C14.0436 9.57031 10.4727 13.1413 10.4727 17.5463C10.4727 21.9513 14.0436 25.5223 18.4486 25.5223Z" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M18.4486 25.5223C22.8536 25.5223 26.4246 21.9513 26.4246 17.5463C26.4246 13.1413 22.8536 9.57031 18.4486 9.57031C14.0436 9.57031 10.4727 13.1413 10.4727 17.5463C10.4727 21.9513 14.0436 25.5223 18.4486 25.5223Z" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M18.4531 0V3.19039" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M18.4531 0V3.19039" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M18.4531 31.9023V35.0927" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M18.4531 31.9023V35.0927" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M6.03906 5.13672L8.30424 7.4019" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M6.03906 5.13672L8.30424 7.4019" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M28.5898 27.6875L30.855 29.9527" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M28.5898 27.6875L30.855 29.9527" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M0.902344 17.5508H4.09273" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M0.902344 17.5508H4.09273" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M32.8047 17.5508H35.9951" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M32.8047 17.5508H35.9951" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M6.03906 29.9527L8.30424 27.6875" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M6.03906 29.9527L8.30424 27.6875" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M28.5898 7.4019L30.855 5.13672" stroke="#1D1D1D" stroke-width="0.71621" stroke-linejoin="round"/>
			<path d="M28.5898 7.4019L30.855 5.13672" stroke="black" stroke-opacity="0.2" stroke-width="0.71621" stroke-linejoin="round"/>
		</svg> -->
	</div>
</template>