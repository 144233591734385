<script setup>

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const pricelist = ref(null)

const pricelistSwiper = ref(null)

const carouselProgress = ref(33.333333)

const handleSlideStart = (data) => {
	carouselProgress.value = (data.realIndex + 1) * (100 / 3)
}

const setActiveSlide = (slideIndex) => {
	pricelistSwiper.value.slideTo(slideIndex)
}

const onSwiper = (swiper) => {
	pricelistSwiper.value = swiper
}

onMounted(() => {

gsap.to('.pricelist > div', {
	scrollTrigger: {
		trigger: pricelist.value,
		start: 'top 95%',
	},
	y: 0,
	stagger: 0.25,
	duration: 0.5,
	opacity: 1,
})
})

</script>

<template>
	<div class="container">
		<div class="bg-turquoise rounded-[30px] py-12 md:py-28">
			<SplitTitle identifier="pricelist">
				<h2 class="h1 text-center mb-10 md:mb-s90 w-full">{{ $t('pricelist.title') }}</h2>
			</SplitTitle>
			<div class="px-5 md:px-s60">
				<div class="hidden xl:grid min-h-[650px] md:min-h-[750px] xl:min-h-full grid-cols-12 xl:gap-8 mb-14 pricelist relative" ref="pricelist">
					<div class="z-30 absolute left-0 top-[225px] md:top-[250px] w-full xl:relative xl:left-auto xl:top-auto xl:w-auto col-span-12 xl:col-span-4 order-3 xl:order-1 opacity-0 translate-y-10">
						<div class="bg-white rounded-[22px] pt-10 xl:pt-12 pb-7 xl:pb-36 px-6 xl:px-s30 h-full relative">
							<div class="text-[30px] md:text-[40px] uppercase  leading-none mb-5 md:mb-7" v-html="$t('pricelist.virtual.title')"></div>
							<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.virtual.text')"></p>
							<ul class="mb-5 md:mb-16" v-html="$t('pricelist.virtual.list')" />
							<div class="md:absolute md:bottom-7 md:left-7 right-6 xl:right-s30">
								<div class="text-lg md:text-xl mb-3 font-bold">2 {{ $t('general.options-available') }}:</div>
								<ul class="list-none pl-0">
									<li class="py-2.5 border-t"><strong><span class="inline-block mr-2">A</span>{{ $t('pricelist.virtual.option1') }}</strong></li>
									<li class="py-2.5 border-y">
										<div class="flex items-center">
											<span class="inline-block mr-2 font-bold"><span class="inline-block mr-2">B</span>{{ $t('pricelist.virtual.option2') }}</span>
											<Badge :text="$t('general.preparing')" />
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="z-20 absolute left-0 top-[116px] md:top-[125px] w-full xl:relative xl:left-auto xl:top-auto xl:w-auto col-span-12 xl:col-span-4 order-2 opacity-0 translate-y-10">
						<div class="rounded-[22px] bg-melrose pt-10 xl:pt-12 pb-7 xl:pb-16 px-6 xl:px-s30 h-full relative">
							<Preparing color="dark" class="absolute right-0 xl:right-5 top-4 xl:top-0" />
							<div class="text-[30px] md:text-[40px] uppercase leading-none mb-5 md:mb-7" v-html="$t('pricelist.avatar.title')" />
							<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.avatar.text')" />
							<ul class="mb-5 md:mb-16" v-html="$t('pricelist.avatar.list')" />
							<div class="md:absolute md:bottom-7 md:left-7 right-6 xl:right-s30">
								<div class="text-lg md:text-xl mb-3 font-bold">2 {{ $t('general.options-available') }}:</div>
								<ul class="list-none pl-0">
									<li class="py-2.5 border-t"><strong><span class="inline-block mr-2">A</span>{{ $t('pricelist.avatar.option1') }}</strong></li>
									<li class="py-2.5 border-y"><strong><span class="inline-block mr-2">B</span>{{ $t('pricelist.avatar.option2') }}</strong></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="z-10 absolute left-0 top-0 w-full xl:relative xl:left-auto xl:top-auto xl:w-auto col-span-12 xl:col-span-4 order-1 xl:order-3 opacity-0 translate-y-10">
						<div class="rounded-[22px] bg-dark text-white pt-10 xl:pt-12 pb-7 xl:pb-16 px-6 xl:px-s30 h-full relative">
							<Preparing class="absolute right-0 xl:right-5 top-4 xl:top-0" />
							<div class="text-[30px] md:text-[40px] uppercase leading-none mb-5 md:mb-7" v-html="$t('pricelist.humanoid.title')" />
							<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.humanoid.text')" />
							<ul class="mb-5 md:mb-16" v-html="$t('pricelist.humanoid.list')" />
						</div>
					</div>
				</div>

				<div class="xl:hidden mb-8">
					<Swiper
						:slides-per-view="1"
						:loop="true"
						:space-between="30"
						@swiper="onSwiper"
						@slide-change="handleSlideStart"
					>
						<SwiperSlide>
							<div class="bg-white rounded-[22px] pt-10 xl:pt-12 pb-7 xl:pb-36 px-6 xl:px-s30 h-full relative">
								<div class="text-[30px] md:text-[40px] uppercase  leading-none mb-5 md:mb-7" v-html="$t('pricelist.virtual.title')"></div>
								<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.virtual.text')"></p>
								<ul class="mb-7" v-html="$t('pricelist.virtual.list')" />
								<div class="">
									<div class="text-lg md:text-xl mb-3 font-bold">2 {{ $t('general.options-available') }}:</div>
									<ul class="list-none pl-0">
										<li class="py-2.5 border-t"><strong><span class="inline-block mr-2">A</span>{{ $t('pricelist.virtual.option1') }}</strong></li>
										<li class="py-2.5 border-y">
											<div class="flex items-center">
												<span class="inline-block mr-2 font-bold"><span class="inline-block mr-2">B</span>{{ $t('pricelist.virtual.option2') }}</span>
												<Badge :text="$t('general.preparing')" />
											</div>
										</li>
									</ul>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide class="h-auto">
							<div class="rounded-[22px] bg-melrose pt-10 xl:pt-12 pb-7 xl:pb-16 px-6 xl:px-s30 h-full relative">
								<Preparing color="dark" class="absolute right-0 xl:right-5 top-4 xl:top-0" />
								<div class="text-[30px] md:text-[40px] uppercase leading-none mb-5 md:mb-7" v-html="$t('pricelist.avatar.title')" />
								<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.avatar.text')" />
								<ul class="mb-7" v-html="$t('pricelist.avatar.list')" />
								<div class="">
									<div class="text-lg md:text-xl mb-3 font-bold">2 {{ $t('general.options-available') }}:</div>
									<ul class="list-none pl-0">
										<li class="py-2.5 border-t"><strong><span class="inline-block mr-2">A</span>{{ $t('pricelist.avatar.option1') }}</strong></li>
										<li class="py-2.5 border-y"><strong><span class="inline-block mr-2">B</span>{{ $t('pricelist.avatar.option2') }}</strong></li>
									</ul>
								</div>
							</div>
						</SwiperSlide>
						<SwiperSlide>
							<div class="rounded-[22px] bg-dark text-white pt-10 xl:pt-12 pb-7 xl:pb-16 px-6 xl:px-s30 h-full relative">
								<Preparing class="absolute right-0 xl:right-5 top-4 xl:top-0" />
								<div class="text-[30px] md:text-[40px] uppercase leading-none mb-5 md:mb-7" v-html="$t('pricelist.humanoid.title')" />
								<p class="text-sm md:text-xl font-bold mb-7 md:mb-10" v-html="$t('pricelist.humanoid.text')" />
								<ul class="mb-7" v-html="$t('pricelist.humanoid.list')" />
							</div>
						</SwiperSlide>
					</Swiper>
					<div class="flex justify-around">
						<div class="py-3 cursor-pointer" @click="setActiveSlide(0)">BE-A Virtual</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(1)">BE-A Avatar</div>
						<div class="py-3 cursor-pointer" @click="setActiveSlide(2)">BE-A Humanoid</div>
					</div>
					<div class="progress-bar h-[3px]">
						<div class="progress-slide h-[3px] bg-dark transition-all duration-300" :style="{ width: `${carouselProgress}%` }"></div>
					</div>
					<div class="flex justify-between mt-6">
						<svg @click="pricelistSwiper.slidePrev()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
							<path d="M9.45703 17.2373L1.00243 8.78234L9.45703 0.328125" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M1.00058 8.78125L24.0586 8.78125" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
						<svg @click="pricelistSwiper.slideNext()" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18" fill="none">
							<path d="M14.6016 0.32907L23.0562 8.78407L14.6016 17.2383" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
							<path d="M23.058 8.78515L0 8.78516" stroke="black" stroke-width="0.843586" stroke-miterlimit="10"/>
						</svg>
					</div>
				</div>

				<p class="text-lg md:text-[26px] mx-auto text-center max-w-3xl leading-6 md:leading-9 mb-7 md:mb-14" v-html="$t('pricelist.text')"></p>
				<div class="text-center"><Button class="inline-block no-arrow-mobile">{{ $t('general.arrange-meeting') }}</Button></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.swiper-slide {
	height: auto;
}
</style>