<script setup>

const { locale } = useI18n()

const props = defineProps({
	color: {
		type: String
	},
})

const colorBg = ref('')
const colorLetter = ref('')

onMounted(() => {
	getLetterColor()
})

const getLetterColor = () => {
	switch (props.color) {
		case 'dark':
			colorBg.value = '#1D1D1D'
			colorLetter.value = '#9FB4FF'
			break;
	
		default:
			colorBg.value = '#9FB4FF'
			colorLetter.value = '#1D1D1D'
			break;
	}
}

</script>

<template>
	<div>
		<svg class="hidden xl:block" xmlns="http://www.w3.org/2000/svg" width="67" height="116" viewBox="0 0 67 116" fill="none">
			<g v-if="locale === 'cs'">
				<path d="M0 0L8.21758e-06 93.9981C9.27986e-06 106.149 9.9992 116 22.3333 116C34.6675 116 44.6667 106.149 44.6667 93.9981L44.6667 22.0019C44.6667 9.85078 54.6659 -4.77904e-06 67 -5.85733e-06L0 0Z" :fill="colorBg"/>
				<path d="M27.0887 11.0603L27.0887 14.8158C27.0887 16.8522 25.796 17.8583 24.2579 17.8583C22.7198 17.8583 21.2861 16.8145 21.2861 14.7644L21.2861 12.7144L18.043 12.7144L18.043 11.0586L27.0904 11.0586L27.0887 11.0603ZM22.9025 14.7781C22.9025 15.6952 23.4976 16.1528 24.2074 16.1528C24.9173 16.1528 25.4741 15.7072 25.4741 14.8158L25.4741 12.7144L22.9025 12.7144L22.9025 14.7764L22.9025 14.7781Z" :fill="colorLetter"/>
				<path d="M27.0904 18.9414L27.0904 22.7484C27.0904 24.7607 25.7716 25.6897 24.3901 25.6897C23.3166 25.6897 22.2831 25.0658 21.869 24.0219L18.043 26.1491L18.043 24.2002L21.4427 22.3661L21.4427 20.5972L18.043 20.5972L18.043 18.9414L27.0904 18.9414ZM23.0591 22.6592C23.0591 23.4494 23.5758 23.9825 24.2875 23.9825C24.9991 23.9825 25.4758 23.4614 25.4758 22.6832L25.4758 20.5955L23.0591 20.5955L23.0591 22.6575L23.0591 22.6592ZM30.0639 20.9794L28.8495 21.9462L30.0639 22.9146L30.0639 24.4676L27.8282 22.6592L27.8282 21.2331L30.0639 19.4248L30.0639 20.9777L30.0639 20.9794Z" :fill="colorLetter"/>
				<path d="M27.0904 27.2969L27.0904 28.9527L18.043 28.9527L18.043 27.2969L27.0904 27.2969Z" :fill="colorLetter"/>
				<path d="M27.0887 30.7322L27.0887 34.4877C27.0887 36.524 25.796 37.5302 24.2579 37.5302C22.7198 37.5302 21.2861 36.4863 21.2861 34.4363L21.2861 32.3863L18.043 32.3863L18.043 30.7305L27.0904 30.7305L27.0887 30.7322ZM22.9025 34.45C22.9025 35.367 23.4976 35.8247 24.2074 35.8247C24.9173 35.8247 25.4741 35.379 25.4741 34.4877L25.4741 32.3863L22.9025 32.3863L22.9025 34.4483L22.9025 34.45Z" :fill="colorLetter"/>
				<path d="M27.0904 38.6172L27.0904 42.4241C27.0904 44.4365 25.7716 45.3655 24.3901 45.3655C23.3166 45.3655 22.2831 44.7416 21.869 43.6977L18.043 45.8249L18.043 43.876L21.4427 42.0419L21.4427 40.273L18.043 40.273L18.043 38.6172L27.0904 38.6172ZM23.0591 42.335C23.0591 43.1252 23.5758 43.6583 24.2875 43.6583C24.9991 43.6583 25.4758 43.1372 25.4758 42.359L25.4758 40.2713L23.0591 40.2713L23.0591 42.3333L23.0591 42.335Z" :fill="colorLetter"/>
				<path d="M27.0904 49.5661L27.0904 51.6796L18.043 55.0409L18.043 53.2325L20.2909 52.4304L20.2909 48.8137L18.043 48.0115L18.043 46.2031L27.0904 49.5644L27.0904 49.5661ZM21.909 51.8579L25.3592 50.622L21.909 49.3862L21.909 51.8561L21.909 51.8579Z" :fill="colorLetter"/>
				<path d="M27.0904 54.5937L27.0904 56.3644L19.8525 58.7709L27.0904 61.1775L27.0904 62.9481L18.043 59.8285L18.043 57.7151L27.0904 54.5955L27.0904 54.5937Z" :fill="colorLetter"/>
				<path d="M21.5213 63.9062L27.0907 63.9062L27.0907 65.562L21.5335 65.562C20.2286 65.562 19.5552 66.174 19.5552 67.4595C19.5552 68.7451 20.2268 69.357 21.5335 69.357L27.0907 69.357L27.0907 71.0128L21.5213 71.0128C19.2334 71.0128 17.9023 69.6878 17.9023 67.4612C17.9023 65.2347 19.2334 63.9097 21.5213 63.9097L21.5213 63.9062Z" :fill="colorLetter"/>
				<path d="M20.4095 71.9492C20.5644 71.9492 20.7332 71.9492 20.9002 71.9749L20.9002 73.617C20.7714 73.5913 20.6549 73.5793 20.5383 73.5793C19.9311 73.5793 19.4909 73.9101 19.4909 74.8272C19.4909 75.6928 19.9954 76.087 20.6931 76.087L25.4744 76.087L25.4744 75.3859C25.4744 74.546 25.4744 73.4507 25.7336 72.4583L27.3239 72.7137C27.1551 73.401 27.0907 74.2666 27.0907 75.3877L27.0907 77.7428L20.6027 77.7428C19.0263 77.7428 17.9023 76.6098 17.9023 74.8392C17.9023 73.0685 18.7688 71.9492 20.4095 71.9492Z" :fill="colorLetter"/>
				<path d="M27.0904 79.3984L27.0904 82.7597C27.0904 83.8807 27.1687 84.7326 27.3236 85.4337L25.7211 85.6754C25.4758 84.6829 25.4758 83.5876 25.4758 82.7597L25.4758 81.0542L23.3827 81.0542L23.3827 84.8235L21.7664 84.8235L21.7663 81.0542L19.6593 81.0542L19.6593 85.5485L18.043 85.5485L18.043 79.3984L27.0904 79.3984Z" :fill="colorLetter"/>
				<path d="M27.0904 86.8867L27.0904 89.5093L19.7881 91.6107L27.0904 93.7122L27.0904 96.3347L18.043 96.3347L18.043 94.7046L24.6859 94.7046L18.043 92.7437L18.043 90.4777L24.6859 88.5168L18.043 88.5168L18.043 86.8867L27.0904 86.8867Z" :fill="colorLetter"/>
				<path d="M27.0904 98.1172L27.0904 101.478C27.0904 102.6 27.1687 103.451 27.3236 104.152L25.7211 104.394C25.4758 103.402 25.4758 102.306 25.4758 101.478L25.4758 99.773L23.3827 99.773L23.3827 103.542L21.7664 103.542L21.7663 99.773L19.6593 99.773L19.6593 104.267L18.043 104.267L18.043 98.1172L27.0904 98.1172Z" :fill="colorLetter"/>
			</g>
			<g v-else>
				<path d="M0 0L8.21758e-06 73.9981C9.27986e-06 86.1492 9.9992 96 22.3333 96C34.6675 96 44.6667 86.1492 44.6667 73.9981L44.6667 22.0019C44.6667 9.85078 54.6659 -4.77904e-06 67 -5.85733e-06L0 0Z" :fill="colorBg"/>
				<path d="M17.8562 77.4754C17.8562 74.8884 19.9492 73.4844 22.5752 73.4844C25.3442 73.4844 27.2422 75.2134 27.2422 77.7614C27.2422 79.6074 26.2412 81.0244 24.7852 81.5054L24.1742 79.8674C25.0322 79.5944 25.5782 78.7884 25.5782 77.7224C25.5782 76.1624 24.4082 75.2264 22.5622 75.2264C20.7682 75.2264 19.3902 76.0064 19.3902 77.7744C19.3902 79.0094 20.1832 79.9454 21.1062 80.0234L21.1062 79.6984C21.1062 78.8664 21.1062 77.7614 21.3532 76.7604L22.9782 77.0074C22.8092 77.7094 22.7312 78.5804 22.7312 79.6984L22.7312 81.6744L17.9992 81.6744L17.9992 80.0234L19.0132 80.0234C18.2852 79.5944 17.8562 78.6064 17.8562 77.4754Z" :fill="colorLetter"/>
				<path d="M17.9977 64.2031L27.0977 64.2031L27.0977 66.1921L20.5457 70.4301L27.0977 70.4301L27.0977 72.1201L17.9977 72.1201L17.9977 70.1311L24.4717 65.8931L17.9977 65.8931L17.9977 64.2031Z" :fill="colorLetter"/>
				<path d="M17.9977 60.6992L27.0977 60.6992L27.0977 62.3892L17.9977 62.3892L17.9977 60.6992Z" :fill="colorLetter"/>
				<path d="M17.9977 52.168L27.0977 52.168L27.0977 56.055C27.0977 58.109 25.7717 59.058 24.3807 59.058C23.3017 59.058 22.2617 58.421 21.8457 57.355L17.9977 59.526L17.9977 57.537L21.4167 55.665L21.4167 53.858L17.9977 53.858L17.9977 52.168ZM23.0417 53.858L23.0417 55.964C23.0417 56.77 23.5617 57.316 24.2767 57.316C24.9917 57.316 25.4727 56.783 25.4727 55.99L25.4727 53.858L23.0417 53.858Z" :fill="colorLetter"/>
				<path d="M17.9977 42.1055L27.0977 45.5375L27.0977 47.6955L17.9977 51.1275L17.9977 49.2815L20.2597 48.4625L20.2597 44.7705L17.9977 43.9515L17.9977 42.1055ZM21.8847 45.3555L21.8847 47.8775L25.3557 46.6165L21.8847 45.3555Z" :fill="colorLetter"/>
				<path d="M17.9977 36.1328L27.0977 36.1328L27.0977 39.9678C27.0977 42.0478 25.7977 43.0748 24.2507 43.0748C22.7167 43.0748 21.2607 42.0088 21.2607 39.9158L21.2607 37.8228L17.9977 37.8228L17.9977 36.1328ZM22.8857 37.8228L22.8857 39.9288C22.8857 40.8648 23.4837 41.3328 24.1987 41.3328C24.9007 41.3328 25.4727 40.8778 25.4727 39.9678L25.4727 37.8228L22.8857 37.8228Z" :fill="colorLetter"/>
				<path d="M17.998 28.4883L27.098 28.4883L27.098 31.9203C27.098 33.0643 27.176 33.9353 27.332 34.6503L25.72 34.8973C25.473 33.8833 25.473 32.7653 25.473 31.9203L25.473 30.1783L23.367 30.1783L23.367 34.0263L21.742 34.0263L21.742 30.1783L19.623 30.1783L19.623 34.7673L17.998 34.7673L17.998 28.4883Z" :fill="colorLetter"/>
				<path d="M17.9977 19.957L27.0977 19.957L27.0977 23.844C27.0977 25.898 25.7717 26.847 24.3807 26.847C23.3017 26.847 22.2617 26.21 21.8457 25.144L17.9977 27.315L17.9977 25.326L21.4167 23.454L21.4167 21.647L17.9977 21.647L17.9977 19.957ZM23.0417 21.647L23.0417 23.753C23.0417 24.559 23.5617 25.105 24.2767 25.105C24.9917 25.105 25.4727 24.572 25.4727 23.779L25.4727 21.647L23.0417 21.647Z" :fill="colorLetter"/>
				<path d="M17.9977 11.9102L27.0977 11.9102L27.0977 15.7452C27.0977 17.8252 25.7977 18.8522 24.2507 18.8522C22.7167 18.8522 21.2607 17.7862 21.2607 15.6932L21.2607 13.6002L17.9977 13.6002L17.9977 11.9102ZM22.8857 13.6002L22.8857 15.7062C22.8857 16.6422 23.4837 17.1102 24.1987 17.1102C24.9007 17.1102 25.4727 16.6552 25.4727 15.7452L25.4727 13.6002L22.8857 13.6002Z" :fill="colorLetter"/>
			</g>
		</svg>
		<svg class="xl:hidden" xmlns="http://www.w3.org/2000/svg" width="89" height="51" viewBox="0 0 89 51" fill="none">
			<path v-if="locale ==='cs'" d="M89 51L16.8807 51C7.55793 51 -3.32702e-07 43.3887 -7.43094e-07 34C-1.15349e-06 24.6113 7.55793 17 16.8807 17L72.1193 17C81.4421 17 89 9.38867 89 -3.8147e-06L89 51Z" :fill="colorBg"/>
			<!-- <path v-else d="M74 51L16.8807 51C7.55793 51 -3.32702e-07 43.3887 -7.43094e-07 34C-1.15349e-06 24.6113 7.55793 17 16.8807 17L57.1193 17C66.4421 17 74 9.38867 74 -3.8147e-06L74 51Z" :fill="colorBg"/> -->
		</svg>
		<svg v-if="locale === 'cs'" class="xl:hidden absolute right-1.5 bottom-3" xmlns="http://www.w3.org/2000/svg" width="73" height="10" viewBox="0 0 73 10" fill="none">
			<path d="M0.954436 2.38007L3.82527 2.38007C5.38189 2.38007 6.15102 3.3641 6.15102 4.53487C6.15102 5.70564 5.35306 6.79694 3.78596 6.79694L2.21886 6.79694L2.21886 9.26563L0.953125 9.26562L0.953125 2.37874L0.954436 2.38007ZM3.79644 5.56657C4.49744 5.56657 4.84729 5.11363 4.84729 4.57327C4.84729 4.03292 4.50662 3.60911 3.82527 3.60911L2.21886 3.60911L2.21886 5.56657L3.79513 5.56657L3.79644 5.56657Z" :fill="colorLetter"/>
			<path d="M6.98047 2.37874L9.89061 2.37874C11.4289 2.37874 12.1391 3.38264 12.1391 4.43421C12.1391 5.25137 11.6621 6.03806 10.8641 6.35327L12.4902 9.26563L11.0004 9.26563L9.59842 6.67775L8.2462 6.67775L8.2462 9.26563L6.98047 9.26562L6.98047 2.37874ZM9.82247 5.44738C10.4265 5.44738 10.834 5.05403 10.834 4.51235C10.834 3.97067 10.4357 3.60779 9.84082 3.60779L8.24489 3.60779L8.24489 5.44738L9.82117 5.44738L9.82247 5.44738ZM8.5384 0.115343L9.2774 1.03977L10.0177 0.115343L11.2048 0.115343L9.82247 1.8172L8.73232 1.8172L7.34997 0.115343L8.53709 0.115343L8.5384 0.115343Z" :fill="colorLetter"/>
			<path d="M13.3672 2.37874L14.6329 2.37874L14.6329 9.26563L13.3672 9.26562L13.3672 2.37874Z" :fill="colorLetter"/>
			<path d="M15.9896 2.38007L18.8604 2.38007C20.417 2.38007 21.1862 3.3641 21.1862 4.53487C21.1862 5.70564 20.3882 6.79694 18.8211 6.79694L17.254 6.79694L17.254 9.26563L15.9883 9.26562L15.9883 2.37874L15.9896 2.38007ZM18.8316 5.56657C19.5326 5.56657 19.8824 5.11363 19.8824 4.57327C19.8824 4.03292 19.5418 3.60911 18.8604 3.60911L17.254 3.60911L17.254 5.56657L18.8303 5.56657L18.8316 5.56657Z" :fill="colorLetter"/>
			<path d="M22.0195 2.37874L24.9297 2.37874C26.4679 2.37874 27.1781 3.38264 27.1781 4.43421C27.1781 5.25137 26.7012 6.03806 25.9032 6.35327L27.5293 9.26563L26.0395 9.26563L24.6375 6.67775L23.2853 6.67775L23.2853 9.26563L22.0195 9.26562L22.0195 2.37874ZM24.8615 5.44738C25.4656 5.44738 25.8731 5.05403 25.8731 4.51235C25.8731 3.97067 25.4748 3.60779 24.8799 3.60779L23.284 3.60779L23.284 5.44738L24.8602 5.44738L24.8615 5.44738Z" :fill="colorLetter"/>
			<path d="M30.3872 2.37874L32.0028 2.37874L34.5722 9.26563L33.1899 9.26563L32.5767 7.5545L29.812 7.5545L29.1988 9.26563L27.8164 9.26562L30.3859 2.37874L30.3872 2.37874ZM32.139 6.32281L31.1943 3.69652L30.2496 6.32281L32.1377 6.32281L32.139 6.32281Z" :fill="colorLetter"/>
			<path d="M34.2344 2.37874L35.5879 2.37874L37.4275 7.88825L39.2672 2.37874L40.6207 2.37874L38.236 9.26563L36.6204 9.26563L34.2357 2.37874L34.2344 2.37874Z" :fill="colorLetter"/>
			<path d="M41.3516 6.61634L41.3516 2.37694L42.6173 2.37694L42.6173 6.60707C42.6173 7.60037 43.0851 8.11291 44.0678 8.11291C45.0505 8.11291 45.5183 7.6017 45.5183 6.60707L45.5183 2.37694L46.784 2.37694L46.784 6.61634C46.784 8.35793 45.7711 9.37109 44.0691 9.37109C42.367 9.37109 41.3542 8.35793 41.3542 6.61634L41.3516 6.61634Z" :fill="colorLetter"/>
			<path d="M47.4961 7.46263C47.4961 7.34476 47.4961 7.21629 47.5158 7.08915L48.771 7.08915C48.7513 7.18716 48.7422 7.27589 48.7422 7.36463C48.7422 7.82684 48.9951 8.16192 49.6961 8.16192C50.3578 8.16192 50.6591 7.77784 50.6591 7.24676L50.6591 3.6073L50.1232 3.6073C49.4812 3.6073 48.6439 3.6073 47.8852 3.40997L48.0805 2.19947C48.6059 2.32793 49.2676 2.37694 50.1245 2.37694L51.9249 2.37694L51.9249 7.31562C51.9249 8.51553 51.0588 9.37109 49.7052 9.37109C48.3517 9.37109 47.4961 8.71154 47.4961 7.46263Z" :fill="colorLetter"/>
			<path d="M53.1953 2.37874L55.7648 2.37874C56.6217 2.37874 57.2729 2.31915 57.8088 2.20127L57.9936 3.42105C57.2349 3.60779 56.3976 3.60779 55.7648 3.60779L54.461 3.60779L54.461 5.20104L57.3424 5.20104L57.3424 6.43141L54.461 6.43141L54.461 8.03526L57.8966 8.03526L57.8966 9.26563L53.1953 9.26562L53.1953 2.37874Z" :fill="colorLetter"/>
			<path d="M58.918 2.37874L60.9227 2.37874L62.5291 7.93725L64.1355 2.37874L66.1403 2.37874L66.1403 9.26563L64.8942 9.26563L64.8942 4.20907L63.3952 9.26563L61.663 9.26563L60.164 4.20907L60.164 9.26563L58.918 9.26562L58.918 2.37874Z" :fill="colorLetter"/>
			<path d="M67.5 2.37874L70.0695 2.37874C70.9264 2.37874 71.5776 2.31915 72.1135 2.20127L72.2983 3.42105C71.5396 3.60779 70.7023 3.60779 70.0695 3.60779L68.7657 3.60779L68.7657 5.20104L71.6471 5.20104L71.6471 6.43141L68.7657 6.43141L68.7657 8.03526L72.2013 8.03526L72.2013 9.26563L67.5 9.26562L67.5 2.37874Z" :fill="colorLetter"/>
		</svg>
		<svg v-else class="xl:hidden absolute right-0 bottom-0" xmlns="http://www.w3.org/2000/svg" width="74" height="51" viewBox="0 0 74 51" fill="none">
			<path d="M74 51L16.8807 51C7.55793 51 -3.32702e-07 43.3887 -7.43094e-07 34C-1.15349e-06 24.6113 7.55793 17 16.8807 17L57.1193 17C66.4421 17 74 9.38867 74 -3.8147e-06L74 51Z" :fill="colorBg"/>
			<path d="M62.1325 38.1106C60.1425 38.1106 59.0625 36.5006 59.0625 34.4806C59.0625 32.3506 60.3925 30.8906 62.3525 30.8906C63.7725 30.8906 64.8625 31.6606 65.2325 32.7806L63.9725 33.2506C63.7625 32.5906 63.1425 32.1706 62.3225 32.1706C61.1225 32.1706 60.4025 33.0706 60.4025 34.4906C60.4025 35.8706 61.0025 36.9306 62.3625 36.9306C63.3125 36.9306 64.0325 36.3206 64.0925 35.6106H63.8425C63.2025 35.6106 62.3525 35.6106 61.5825 35.4206L61.7725 34.1706C62.3125 34.3006 62.9825 34.3606 63.8425 34.3606H65.3625V38.0006H64.0925V37.2206C63.7625 37.7806 63.0025 38.1106 62.1325 38.1106Z" :fill="colorLetter"/>
			<path d="M51.9219 38V31H53.4519L56.7119 36.04V31H58.0119V38H56.4819L53.2219 33.02V38H51.9219Z" :fill="colorLetter"/>
			<path d="M49.2266 38V31H50.5266V38H49.2266Z" :fill="colorLetter"/>
			<path d="M42.668 38V31H45.658C47.238 31 47.968 32.02 47.968 33.09C47.968 33.92 47.478 34.72 46.658 35.04L48.328 38H46.798L45.358 35.37H43.968V38H42.668ZM43.968 34.12H45.588C46.208 34.12 46.628 33.72 46.628 33.17C46.628 32.62 46.218 32.25 45.608 32.25H43.968V34.12Z" :fill="colorLetter"/>
			<path d="M34.9258 38L37.5658 31H39.2258L41.8658 38H40.4458L39.8158 36.26H36.9758L36.3458 38H34.9258ZM37.4258 35.01H39.3658L38.3958 32.34L37.4258 35.01Z" :fill="colorLetter"/>
			<path d="M30.332 38V31H33.282C34.882 31 35.672 32 35.672 33.19C35.672 34.37 34.852 35.49 33.242 35.49H31.632V38H30.332ZM31.632 34.24H33.252C33.972 34.24 34.332 33.78 34.332 33.23C34.332 32.69 33.982 32.25 33.282 32.25H31.632V34.24Z" :fill="colorLetter"/>
			<path d="M24.4531 38.0003V31.0003H27.0931C27.9731 31.0003 28.6431 30.9403 29.1931 30.8203L29.3831 32.0603C28.6031 32.2503 27.7431 32.2503 27.0931 32.2503H25.7531V33.8703H28.7131V35.1203H25.7531V36.7503H29.2831V38.0003H24.4531Z" :fill="colorLetter"/>
			<path d="M17.8906 38V31H20.8806C22.4606 31 23.1906 32.02 23.1906 33.09C23.1906 33.92 22.7006 34.72 21.8806 35.04L23.5506 38H22.0206L20.5806 35.37H19.1906V38H17.8906ZM19.1906 34.12H20.8106C21.4306 34.12 21.8506 33.72 21.8506 33.17C21.8506 32.62 21.4406 32.25 20.8306 32.25H19.1906V34.12Z" :fill="colorLetter"/>
			<path d="M11.6992 38L11.6992 31H14.6492C16.2492 31 17.0392 32 17.0392 33.19C17.0392 34.37 16.2192 35.49 14.6092 35.49H12.9992L12.9992 38H11.6992ZM12.9992 34.24H14.6192C15.3392 34.24 15.6992 33.78 15.6992 33.23C15.6992 32.69 15.3492 32.25 14.6492 32.25H12.9992V34.24Z" :fill="colorLetter"/>
		</svg>
	</div>
</template>