<template>
	<a href="https://calendly.com/filip-linek/30min?back=1&month=2024-11" target="_blank" class="button">
		<span class="text"><slot /></span>
		<svg xmlns="http://www.w3.org/2000/svg" width="25" height="18" viewBox="0 0 25 18" fill="none">
			<path d="M15.0508 0.567351L23.5054 9.02235L15.0508 17.4766" stroke="#1D1D1D" stroke-width="0.843586" stroke-miterlimit="10"/>
			<path d="M23.5072 9.02344L0.449219 9.02344" stroke="#1D1D1D" stroke-width="0.843586" stroke-miterlimit="10"/>
		</svg>
	</a>
</template>

<style lang="scss" scoped>
.button {
	position: relative;
	overflow: hidden;
	border-radius: 36.134px;
	border: 1px solid theme('colors.dark');
	padding: 18px 24px;
	text-transform: uppercase;
	line-height: 1.25;
	white-space: nowrap;

	@media (min-width: 640px) {
		padding: 18px 72px;
	}

	.text {
		display: block;
		position: relative;
	}

	&:hover {
		.text {
			animation: MoveUpInitial 0.15s forwards, MoveUpEnd 0.15s forwards 0.15s;
		}
	}

	svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 18px;
	}

	&.no-arrow-mobile {
		svg {
			display: none;

			@media (min-width: 640px) {
				display: block;
			}
		}
	}
}

@keyframes MoveUpInitial {
	100% {
		transform: translate3d(0, -205%, 0);
	}
}

@keyframes MoveUpEnd {
	0% {
		transform: translate3d(0, 200%, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}
</style>