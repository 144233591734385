<script setup>

	const { locale } = useI18n()

	import { gsap } from "gsap";
	import { ScrollTrigger } from "gsap/ScrollTrigger";
	gsap.registerPlugin(ScrollTrigger);

	const beaPosition = ref('fixed')
	const secondPart = ref(null)
	const bea = ref(null)
	const title = ref(null)

	const { $listen } = useNuxtApp()

	$listen('resetBea', () => {
		beaPosition.value = 'absolute'
	})

	const frameCount = ref(1)
	const urls =new Array(frameCount.value).fill().map((o, i) => `/sequence4/bea_${(i).toString().padStart(5, '0')}.png`);
	const imageSequence = (config) => {
		let playhead = {frame: 0},
			ctx = gsap.utils.toArray(config.canvas)[0].getContext("2d"),
			onUpdate = config.onUpdate,
			images,
			updateImage = function() {
				ctx.clearRect(0, 0,
                bea.value.width, bea.value.height);
				ctx.drawImage(images[Math.round(playhead.frame)], 0, 0);
				onUpdate && onUpdate.call(this);
			};
		images = config.urls.map((url, i) => {
			let img = new Image();
			img.src = url;
			i || (img.onload = updateImage);
			return img;
		});
		return gsap.to(playhead, {
			frame: images.length - 1,
			ease: "none",
			onUpdate: updateImage,
			scrollTrigger: config.scrollTrigger
		});
	}

	onMounted(() => {

		imageSequence({
			urls,
			canvas: bea.value,
			scrollTrigger: {
				start: 0,
				end: window.innerWidth >= 1400 ? 1500 : 1000,
				scrub: true
			}
		});

		gsap.from('.bubble', {
			scrollTrigger: {
				trigger: secondPart.value,
				toggleActions: 'play none reverse none',
				start: 'top top',
				end: '+=300',
				scrub: true,
			},
			opacity: 0,
			scale: 0,
			duration: 3,
			stagger: 0.5
		})

		gsap.from('.text-bubble', {
			scrollTrigger: {
				trigger: secondPart.value,
				toggleActions: 'play none reverse none',
				start: 'top top',
				end: '+=200',
				scrub: true,
			},
			opacity: 0,
			scale: 0,
			duration: 3,
			stagger: 0.1
		})

		gsap.from(title.value, {
			scrollTrigger: {
				trigger: secondPart.value,
				toggleActions: 'play none reverse none',
				start: 'top top',
				end: '+=200',
				scrub: true,
			},
			opacity: 0,
			scale: 0,
			duration: 2
		})

		if (window.innerWidth >= 1024) {
			gsap.to(bea.value, {
				scrollTrigger: {
					trigger: bea.value,
					toggleActions: 'play none reverse none',
					start: 0,
					end: 1500,
					scrub: true,
				},
				scale: 0.75,
				duration: 5
			})
		} else {
			gsap.to(bea.value, {
				scrollTrigger: {
					trigger: bea.value,
					toggleActions: 'play none reverse none',
					start: 0,
					end: 1500,
					scrub: true,
				},
				scale: 0.95,
				duration: 5
			})
		}
		
		gsap.to(secondPart.value, {
			scrollTrigger: {
				trigger: secondPart.value,
				onToggle: (self) => {
					if (self.isActive && self.direction) {
						beaPosition.value = 'fixed'
					} else if (self.isActive && self.direction === -1) {
						beaPosition.value = 'absolute'
					} else if (!self.isActive && self.direction === -1) {
						beaPosition.value = 'fixed'
					} else {
						beaPosition.value = 'absolute'
					}
				},
				start: 'top top',
				end: 'bottom center',
				pin: true,
				scrub: true,
			},
		}) 
	})

</script>

<template>

	<div class="bea relative max-w-screen-[2560px] mx-auto overflow-hidden">

		<canvas ref="bea" :class="beaPosition" class="bottom-0 left-[50%] -translate-x-[50%] origin-bottom z-20 max-h-[95vh] max-w-[225vw] md:max-w-[175vw] lg:max-w-[120vw] xl:max-w-full" width="2400" height="1350" />

		<div class="w-screen h-screen max-w-full relative ">
			<div class="container max-w-full h-full relative">
				<div :class="locale" class="absolute top-4 md:top-[30px] bottom-4 md:bottom-[30px] left-4 md:left-[30px] right-4 md:right-[30px] bg-turquoise rounded-[30px] rounded-tr-[100px] bg">
					<Weather class="absolute top-[30px] left-[30px]" />
					<svgo-bea class="absolute z-20 bottom-[30px] left-4 right-4 md:right-auto md:left-[30px] md:w-[77vw] lg:w-[40vw] xl:w-[60vw] text-dark" :fontControlled="false" />
				</div>
			</div>
		</div>
		<div class="w-screen h-screen max-w-full relative z-30" ref="secondPart">
			<div class="max-w-full h-full relative">
				<h1 class="text-center text-[7vh] mb-[5vh] md:mb-0 md:text-[8vh] lg:text-[10vh] pt-[5vh] leading-none" ref="title" v-html="$t('bea.title')" />
				<div class="bubbles w-1 mx-auto">
					<div class="bubble absolute w-[30vw] md:w-[25vw] lg:w-[12.5vw] translate-x-[15vw] translate-y-[15vh] md:translate-y-[5vh]">
						<NuxtImg class="w-full" src="/img/bubble-hi.svg" />
					</div>
					<div class="bubble absolute z-50 w-[25vw] lg:w-[9.5vw] -translate-x-[45vw] md:-translate-x-[40vw] lg:-translate-x-[20vw] translate-y-[50vh]">
						<NuxtImg class="w-full" src="/img/bubble-ola.svg" />
					</div>
					<div class="text-bubble absolute z-50 w-[245px] md:w-auto -translate-x-[45vw] md:-translate-x-[40vw] lg:-translate-x-[30vw] translate-y-[5vh] md:translate-y-[15vh] lg:translate-y-[20vh]">
						<div class="text-white p-4 lg:p-6 bg-dark rounded-3xl text-sm lg:text-[1.1111vw] leading-[137%]" v-html="$t('bea.bubble-1')" />
					</div>
					<div class="text-bubble absolute z-40 w-[205px] md:w-auto -translate-x-[5vw] md:translate-x-[10vw] translate-y-[50vh] lg:translate-y-[40vh]">
						<div class="bubble absolute z-50 w-[25vw] sm:w-[12vw] lg:w-[9.5vw] -top-full left-1/2 md:left-[75%]">
							<NuxtImg class="w-full" src="/img/bubble-bonjour.svg" />
						</div>
						<div class="text-white p-4 lg:p-8 bg-dark rounded-3xl text-sm lg:text-[1.1111vw] leading-[137%]" v-html="$t('bea.bubble-2')" />
					</div>
				</div>
			</div>

			
		</div>

	</div>

</template>

<style lang="scss" scoped>

	.bubble {
		container-type: size;
	}

	.bg {
		background-image: url('/img/bg.svg');
		background-position: right -1px top;
		background-size: 45%;
		background-repeat: no-repeat;

		&.en {
			background-image: url('/img/bg-en.svg');
		}

		@media (min-width: 768px) {
			background-size: 45%;
		}

		@media (min-width: 1280px) {
			background-size: contain;
		}
	}

</style>